import React from 'react'
import Link from 'next/link'
import {
  getPriceCurrency,
  getCurrentStoredPrice,
  getMaxPreviousPrice,
  hasPriceReduced,
} from '@carrotcart/common/lib/pricingHelpers'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import type { DiscoveryFeedFeedItemFragment } from '@carrotcart/data/generated'
import formatItemPrice from '@carrotcart/client-common/lib/formatItemPrice'
import ProductCardImage from '@carrotcart/app/components/ProductCardImage'

interface CartItemCardProps {
  item: DiscoveryFeedFeedItemFragment
}

const CartItemCard: React.FC<CartItemCardProps> = ({ item }) => {
  const collectionCartItem = item.collection_cart_item
  const creator = collectionCartItem.cart_item?.cart?.user
  const collection = collectionCartItem.collection
  const cartItem = collectionCartItem.cart_item

  const currency = getPriceCurrency(
    collectionCartItem?.cart_item?.item,
    collectionCartItem?.cart_item?.pricing
  )
  const currentPrice = getCurrentStoredPrice(
    collectionCartItem?.cart_item?.item,
    collectionCartItem?.cart_item?.pricing
  )
  const previousPrice = getMaxPreviousPrice(collectionCartItem?.cart_item?.item)
  const shouldShowPriceDrop = hasPriceReduced({
    previousPrice,
    currentPrice,
  })

  const displayPrice = formatItemPrice(currentPrice, {
    currency,
  })
  const showPrice = displayPrice !== null && displayPrice !== 'See price'

  const collectionWithModalUrl = `/${
    collection.user?.username || creator?.username
  }/${collection.slug}/${cartItem?.id}`

  return (
    <div className="relative">
      <Link
        href={collectionWithModalUrl}
        target="_blank"
        onClick={() =>
          analytics.track(AnalyticsEventName.ClickedDiscoveryItem, {
            itemId: cartItem?.id,
            source: 'discover',
            cardType: 'item',
          })
        }
      >
        <div className="w-full aspect-1 relative overflow-hidden rounded-lg cursor-pointer">
          <ProductCardImage
            item={collectionCartItem?.cart_item?.item}
            itemImageOverride={
              collectionCartItem?.cart_item?.item_image_override
            }
            objectFit="cover"
          />
        </div>
      </Link>
      {showPrice && (
        <div
          className="absolute top-4 right-4 rounded-full bg-white px-4 py-2"
          style={{
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
          }}
        >
          <p className="text-sm text-black font-medium">{displayPrice}</p>
        </div>
      )}
      <div
        className="bg-white flex-row items-center py-3 px-4 hidden"
        style={{
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        }}
      >
        <div className="mr-2">
          {/* <ShopFavicon
            size="md"
            favicon={collectionCartItem?.cart_item?.shop?.favicon}
          /> */}
        </div>
        <div className="flex-1">
          <p
            className="font-bold"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {collectionCartItem?.cart_item?.display_title}
          </p>
        </div>
        <div className="ml-3">
          <p
            className={`${
              shouldShowPriceDrop ? 'text-drop font-bold' : ''
            } text-sm text-slate`}
          >
            {formatItemPrice(currentPrice, {
              currency,
            })}
          </p>
          {shouldShowPriceDrop && (
            <p className="text-sm text-slate line-through">
              {formatItemPrice(previousPrice, {
                currency,
              })}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default CartItemCard
