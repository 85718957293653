import Link from 'next/link'
import type { FeaturedShopAndFeaturedShopItemsFragment } from '@carrotcart/data/generated'
import ProductCardImage from '@carrotcart/app/components/ProductCardImage'

interface DiscoverShopProps {
  shop: FeaturedShopAndFeaturedShopItemsFragment
}

const DiscoverShop: React.FC<DiscoverShopProps> = ({ shop }) => {
  const {
    featured_shop_items,
    shop: { name },
  } = shop

  const shopUrl = `/shop/${shop.shop?.name?.toLowerCase()}`

  return (
    <div>
      <Link
        href={shopUrl}
        target="_blank"
        className="text-3xl font-bold mb-2 mt-4"
      >
        {name}
      </Link>
      <div className="relative overflow-hidden">
        <div className="grid grid-cols-4 grid-rows-1 gap-1">
          {featured_shop_items.slice(0, 4).map((item) => {
            return (
              <Link
                href={shopUrl}
                key={item.id}
                target="_blank"
                className="cursor-pointer aspect-[4/5] overflow-hidden rounded-lg"
              >
                <ProductCardImage item={item.shop_item} objectFit="cover" />
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default DiscoverShop
