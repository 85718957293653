import React from 'react'
import ActionButton, {
  ActionButtonType,
} from '@carrotcart/app/components/ActionButton'
import ShareIcon from '@carrotcart/app/components/svg/ShareIcon'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  type?: ActionButtonType
}

const ShareButton: React.FC<Props> = ({ type, ...props }) => {
  return (
    <ActionButton type={type} {...props}>
      <ShareIcon className="w-5 h-5" />
    </ActionButton>
  )
}

export default ShareButton
