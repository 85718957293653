import Link from 'next/link'
import type { ItemDataFragment } from '@carrotcart/data/generated'
import type { DiscoveryFeedFeedItemFragment } from '@carrotcart/data/graphql/fragments.generated'
import ProductCardImage from '@carrotcart/app/components/ProductCardImage'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import DiscoverBottom from './DiscoverBottom'

interface DiscoverCollectionProps {
  feedItem: DiscoveryFeedFeedItemFragment
}

const DiscoverCollection: React.FC<DiscoverCollectionProps> = ({
  feedItem,
}) => {
  const collection = feedItem.collection
  const creator = collection?.user
  const mainImageItem = collection?.collection_items
    ? collection?.collection_items[0]
    : undefined
  const secondaryImageItem = collection?.collection_items
    ? collection?.collection_items[1]
    : undefined
  const tertiaryImageItem = collection?.collection_items
    ? collection?.collection_items[2]
    : undefined

  const description =
    (collection?.description || '').length > 180
      ? (collection?.description || '').slice(0, 180) + '...'
      : collection?.description || ''

  const collectionUrl = `/${creator.username}/${collection?.slug}`

  return (
    <>
      <div
        className="overflow-hidden rounded-lg"
        onClick={() =>
          analytics.track(AnalyticsEventName.ClickedDiscoveryItem, {
            collectionId: collection?.id,
            source: 'discover',
            cardType: 'collection',
          })
        }
      >
        <div className="aspect-[5/4] grid grid-rows-2 grid-cols-[1.3fr_0.7fr] gap-1 overflow-hidden rounded-lg">
          {mainImageItem && (
            <Link href={collectionUrl} target="_blank" className="row-span-2">
              <ProductCardImage
                item={
                  (mainImageItem?.cart_item?.item ||
                    undefined) as ItemDataFragment
                }
                itemImageOverride={
                  collection?.cover_image ||
                  mainImageItem?.cart_item?.item_image_override
                }
                objectFit="cover"
                objectPosition="center"
                className="cursor-pointer"
              />
            </Link>
          )}

          {secondaryImageItem && (
            <Link
              href={collectionUrl}
              target="_blank"
              className="col-span-1 overflow-hidden"
            >
              <ProductCardImage
                item={
                  (secondaryImageItem?.cart_item?.item ||
                    undefined) as ItemDataFragment
                }
                itemImageOverride={
                  secondaryImageItem?.cart_item?.item_image_override
                }
                objectFit="cover"
                objectPosition="center"
                className="cursor-pointer"
              />
            </Link>
          )}

          {tertiaryImageItem && (
            <Link
              href={collectionUrl}
              target="_blank"
              className="col-span-1 overflow-hidden"
            >
              <ProductCardImage
                item={
                  (tertiaryImageItem?.cart_item?.item ||
                    undefined) as ItemDataFragment
                }
                itemImageOverride={
                  tertiaryImageItem?.cart_item?.item_image_override
                }
                objectFit="cover"
                objectPosition="center"
                className="cursor-pointer"
              />
            </Link>
          )}
        </div>

        <Link href={collectionUrl} target="_blank">
          <div className="mt-4">
            <h1 className="font-bold text-black text-3xl">
              {collection?.name}
            </h1>
            <div className="text-gray-500">{description}</div>
          </div>
        </Link>
      </div>
      <div className="mt-3">
        <DiscoverBottom feedItem={feedItem} />
      </div>
    </>
  )
}

export default DiscoverCollection
