import {
  GetShopItemForShopItemHistoryProcessingQuery,
  ItemDataFragment,
} from '@carrotcart/data/generated'

export const getCurrentStoredPrice = (
  shopItem:
    | ItemDataFragment
    | GetShopItemForShopItemHistoryProcessingQuery['shop_item_by_pk']
    | undefined,
  priceOverride?:
    | undefined
    | {
        price: number
        currency: string
      }
): number => {
  return (
    priceOverride?.price ||
    shopItem?.parsed_pricing?.price ||
    shopItem?.pricing?.price ||
    0
  )
}

export const getPreviousStoredPrice = (
  shopItem: ItemDataFragment | undefined
): number =>
  shopItem?.parsed_pricing?.previousPrice || shopItem?.pricing?.price || 0

export const getRrpPrice = (shopItem: ItemDataFragment | undefined): number =>
  shopItem?.parsed_pricing?.rrpPrice || 0

export const getMaxPreviousPrice = (
  shopItem: ItemDataFragment | undefined
): number =>
  Math.max(
    getRrpPrice(shopItem) || getPreviousStoredPrice(shopItem),
    getSavedPrice(shopItem)
  )

export const getSavedPrice = (shopItem: ItemDataFragment | undefined): number =>
  shopItem?.pricing?.retailPrice || shopItem?.pricing?.price || 0

export const getPriceCurrency = (
  shopItem: ItemDataFragment | undefined,
  priceOverride?:
    | undefined
    | {
        price: number
        currency: string
      }
): string => {
  return (
    priceOverride?.currency ||
    shopItem?.pricing?.currency ||
    shopItem?.parsed_pricing?.currency ||
    'USD'
  )
}

export const hasPriceReduced = ({
  previousPrice,
  currentPrice,
}: {
  previousPrice: number
  currentPrice: number
}): boolean => {
  return previousPrice > currentPrice
}
