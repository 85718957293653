import { useState } from 'react'
import { GetDiscoveryFeedWebQuery } from '@carrotcart/data/generated'
import CopyToClipboard from 'react-copy-to-clipboard'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import { createPublicUrl } from '@carrotcart/client-common/lib/helpers'
import useGetDiscoverData from '@carrotcart/app/hooks/useGetDiscoverData'
import AddToCarrotButton from '@carrotcart/app/components/atc-button/AddToCarrotButton'
import LikeButton from '@carrotcart/app/components/LikeButton'
import ShareButton from '@carrotcart/app/components/ShareButton'
import CommentIcon from '@carrotcart/app/components/svg/CommentIcon'
import ActionButton from '@carrotcart/app/components/ActionButton'
import Link from 'next/link'

interface DiscoverBottomProps {
  feedItem: GetDiscoveryFeedWebQuery['discovery_feed'][number]['feed_item']
}

const DiscoverBottom: React.FC<DiscoverBottomProps> = ({ feedItem }) => {
  const [showCopied, setShowCopied] = useState(false)
  const { collection, cartItem, collectionWithModalUrl } = useGetDiscoverData({
    feedItem,
  })

  const likeFeedItem = cartItem.feed_item || collection.feed_item
  const totalLikes = collection.total_likes_count

  const shareLink = createPublicUrl(collectionWithModalUrl)

  const nativeShare = () => {
    window?.navigator?.share &&
      navigator.share({
        title: cartItem.item.display_title,
        text: 'Check out this item on Carrot',
        url: shareLink,
      })

    analytics.track(AnalyticsEventName.CopiedShareUrl, {
      source: 'discover',
      itemId: cartItem?.id,
    })
  }

  return (
    <div className="relative flex items-center">
      <div className="mr-2">
        <AddToCarrotButton
          page="discover"
          collectionId={collection?.id}
          shopItem={cartItem.item}
          cartItem={cartItem}
          size="xlarge"
          type="block"
        />
      </div>
      <div className="mr-2">
        <LikeButton
          feedItem={likeFeedItem}
          totalCount={totalLikes}
          source="discovery"
        />
      </div>
      <div className="mr-2">
        <Link href={collectionWithModalUrl} target="_blank">
          <ActionButton>
            <CommentIcon />
          </ActionButton>
        </Link>
      </div>
      <div className="mr-2">
        <CopyToClipboard
          text={shareLink}
          onCopy={() => {
            setShowCopied(true)
            setTimeout(() => {
              setShowCopied(false)
            }, 700)
          }}
        >
          <div className="flex items-center">
            <ShareButton onClick={nativeShare} />
            {showCopied && (
              <div className="bg-black text-white h-[44px] flex items-center rounded-full mx-2 px-3">
                <span className="md:hidden">Copied</span>
                <span className="hidden md:inline">Copied to clipboard</span>
              </div>
            )}
          </div>
        </CopyToClipboard>
      </div>
    </div>
  )
}

export default DiscoverBottom
