import React, { HTMLAttributes } from 'react'
import clsx from 'clsx'
import { GlobeAltIcon } from '@heroicons/react/solid'
import { Favicon } from '@carrotcart/common/types'

interface ShopFaviconProps extends HTMLAttributes<HTMLDivElement> {
  src?: Favicon
  shadow?: boolean
}

const ShopFavicon: React.FC<ShopFaviconProps> = ({
  src,
  shadow = true,
  className,
  style,
}) => {
  return (
    <>
      {src && src !== 'undefined' && src !== 'data:,' ? (
        <div
          className={clsx(
            'flex justify-center items-center h-7 w-7 rounded-full ring-2 ring-white bg-white overflow-hidden',
            className,
            {
              'shadow-lg': shadow,
            }
          )}
          style={style}
        >
          <img
            className="inline-block w-full h-full object-contain"
            src={src}
            alt=""
          />
        </div>
      ) : (
        <div
          className={clsx(
            'h-7 w-7 rounded-full ring-2 ring-white bg-white overflow-hidden',
            className,
            {
              'shadow-lg': shadow,
            }
          )}
          style={style}
        >
          <GlobeAltIcon className="h-full w-full text-gray-300" />
        </div>
      )}
    </>
  )
}

export default ShopFavicon
