import React from 'react'
import clsx from 'clsx'
import type { FeedItemDataFragment } from '@carrotcart/data/graphql/fragments.generated'
import { useLikeFeedItemActionMutation } from '@carrotcart/data/graphql/likeFeedItemAction.generated'
import connectToContext, {
  SelectorFn,
} from '@carrotcart/client-common/hoc/connectToContext'
import ActionButton, {
  ActionButtonType,
} from '@carrotcart/app/components/ActionButton'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { useSignupWallContext } from '@carrotcart/app/context/SignupWallProvider'
import HeartIcon from '@carrotcart/app/components/svg/HeartIcon'
import HeartIconFilled from '@carrotcart/app/components/svg/HeartIconFilled'

interface ContextSelectorProps {
  currentUserId: string | null
}

interface Props
  extends ContextSelectorProps,
    Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  feedItem: FeedItemDataFragment
  totalCount?: number
  type?: ActionButtonType
  size?: 'small' | 'large'
  source?: string
}

const useSelector: SelectorFn<ContextSelectorProps> = () => {
  const { currentUser } = useWebAppContext()

  return {
    currentUserId: currentUser?.id,
  }
}

const LikeButton: React.FC<Props> = ({
  feedItem,
  totalCount,
  type,
  size = 'large',
  source,
  currentUserId,
}) => {
  const { openSignupWall } = useSignupWallContext()
  const [likeFeedItemMutation, { loading: likeLoading }] =
    useLikeFeedItemActionMutation()

  const likedbyCurrentUser = !!feedItem?.liked_by_user
  const likesCount = totalCount || feedItem?.like_count || 0

  const heartItem = async (
    event: React.MouseEvent<HTMLElement>
  ): Promise<void> => {
    event.preventDefault()
    event.stopPropagation()

    if (likeLoading) return

    if (!currentUserId) {
      openSignupWall({
        type: 'with_custom_callback',
        trackingData: {
          source,
          trigger: 'like',
        },
        callback: async () => {
          await likeFeedItemMutation({
            variables: {
              input: {
                feed_item_id: feedItem.id,
                value: !likedbyCurrentUser,
              },
            },
          })
        },
      })
      return
    }

    await likeFeedItemMutation({
      variables: {
        input: {
          feed_item_id: feedItem.id,
          value: !likedbyCurrentUser,
        },
      },
    })
  }

  return (
    <ActionButton
      type={type}
      onClick={heartItem}
      className={clsx(
        'group flex items-center justify-center rounded-full space-x-1 transition-all drop-shadow-sm',
        {
          'opacity-50': likeLoading,
          'h-[30px]': size === 'small',
          'h-[44px]': size === 'large',
          'w-[30px]': !likesCount && size === 'small',
          'w-[44px]': !likesCount && size === 'large',
          'px-2': !!likesCount && size === 'small',
          'px-3': !!likesCount && size === 'large',
          '!bg-red': likedbyCurrentUser,
        }
      )}
    >
      {likedbyCurrentUser ? (
        <HeartIconFilled
          className={clsx('text-white', {
            'w-4 h-4': size === 'small',
            'w-6 h-6': size === 'large',
          })}
        />
      ) : (
        <HeartIcon
          className={clsx({
            'w-4 h-4': size === 'small',
            'w-6 h-6': size === 'large',
          })}
        />
      )}

      {!!likesCount && (
        <div
          className={clsx('font-semibold px-1 text-sm', {
            'text-xs': size === 'small',
            'text-white': likedbyCurrentUser,
          })}
        >
          {likesCount}
        </div>
      )}
    </ActionButton>
  )
}

export default connectToContext(LikeButton, useSelector)
