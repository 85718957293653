import {
  Feed_Item_Type_Enum,
  GetDiscoveryFeedQuery,
} from '@carrotcart/data/generated'
import React from 'react'
import DiscoverShop from './DiscoverShop'
import DiscoverCollectionGallery from './DiscoverCollectionGallery'
import DiscoverCollection from './DiscoverCollection'
import CartItemCard from './DiscoverCartItem'
import DiscoverBottom from './DiscoverBottom'

interface DiscoverItemProps {
  feedItem: GetDiscoveryFeedQuery['discovery_feed'][number]['feed_item']
}

const DiscoverItem: React.FC<DiscoverItemProps> = ({ feedItem }) => {
  switch (feedItem.item_type) {
    case Feed_Item_Type_Enum.FeaturedShop: {
      return <DiscoverShop shop={feedItem.featured_shop} />
    }
    case Feed_Item_Type_Enum.Collection: {
      return feedItem.metadata.mode === 'gallery' ? (
        <DiscoverCollectionGallery feedItem={feedItem} />
      ) : (
        <DiscoverCollection feedItem={feedItem} />
      )
    }
    case Feed_Item_Type_Enum.CollectionCartItem: {
      // TODO: replace with gallery mode coming from backend
      const feedItemId = feedItem.id
      const seed = feedItemId.replace(/\D/g, '')[0]
      const isGallery = feedItem?.metadata?.mode === 'gallery' || seed % 2 === 0
      if (isGallery) {
        return <DiscoverCollectionGallery feedItem={feedItem} />
      }
      return (
        <>
          <CartItemCard item={feedItem} />
          <div className="mt-3">
            <DiscoverBottom feedItem={feedItem} />
          </div>
        </>
      )
    }
    default:
      return null
  }
}

export default DiscoverItem
