import Button from '@carrotcart/client-common/components/Button'
import Link from 'next/link'

const GetCarrotAd: React.FC = () => {
  return (
    <Link passHref href="/signup">
      <div
        className="w-full aspect-[.66] rounded-lg"
        style={{
          backgroundImage: 'url(/images/rabbithole/join-carrot-1.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="w-full py-6 px-7 rounded-lg flex flex-col items-center">
          <div className="pt-4 text-white text-[34px] leading-9 font-bold mb-5 text-center">
            Find your next favorite thing
          </div>
          <Button
            size="large"
            className="text-white bg-[#5D63D9] w-full hover:bg-[#5D63D9]/80"
          >
            Get started
          </Button>
        </div>
      </div>
    </Link>
  )
}

export default GetCarrotAd
