import clsx from 'clsx'
import Image, { ImageLoader } from 'next/legacy/image'
import imageKitLoader from '@carrotcart/client-common/lib/imageKitLoader'
import { PLACEHOLDER_IMAGE_SRC } from '@carrotcart/app/lib/constants'

interface Props extends React.ComponentProps<typeof Image> {
  setPaddingTop?: React.Dispatch<React.SetStateAction<string>>
  disableScaling?: boolean
  shouldProxy?: boolean
  imageSrcIsPlaceholder?: boolean
  storedWidth?: string | number
  onErrorCallback?: React.ReactEventHandler<HTMLImageElement>
  loaderCallback?: ImageLoader
}

const DynamicImage: React.FC<Props> = ({
  disableScaling,
  shouldProxy,
  src,
  imageSrcIsPlaceholder,
  layout,
  width,
  storedWidth,
  height,
  sizes,
  objectFit,
  objectPosition,
  priority,
  setPaddingTop,
  onErrorCallback,
  loaderCallback,
  ...props
}) => {
  return (
    <Image
      src={src}
      className={clsx({
        'lg:transition-transform lg:scale-100 lg:group-hover:scale-105':
          !disableScaling,
      })}
      loader={({ src, width, quality }) => {
        // just return if the placeholder is set or we should not proxy
        if (src === PLACEHOLDER_IMAGE_SRC || !shouldProxy) {
          return src
        }

        // If an image is being proxied via our other image proxy just return that
        if (src.toLocaleLowerCase().startsWith('https://img-proxy.crrt.dev/')) {
          try {
            const url = new URL(src)
            url.searchParams.set('width', `${width}`)
            return url.toString()
          } catch (err) {
            return src
          }
        }

        if (loaderCallback) {
          return loaderCallback({ src, width, quality })
        }

        // catch all
        return imageKitLoader({ src, width, quality })
      }}
      layout={layout || width ? 'intrinsic' : 'fill'}
      sizes={sizes}
      width={width}
      height={height}
      objectFit={objectFit || (imageSrcIsPlaceholder ? 'contain' : 'cover')}
      objectPosition={objectPosition || 'center'}
      priority={priority}
      onLoadingComplete={({ naturalWidth, naturalHeight }) => {
        const hasNaturalDimensions = !!naturalWidth && !!naturalHeight

        if (setPaddingTop && !storedWidth && !width && hasNaturalDimensions) {
          const ratio = naturalWidth / naturalHeight
          const maxRatio = Math.max(3 / 4, ratio)

          // used for aspect ratio
          setPaddingTop(`calc(100% / (${maxRatio})`)
        }
      }}
      onError={onErrorCallback}
      {...props}
    />
  )
}

export default DynamicImage
