import React from 'react'
import clsx from 'clsx'

export type ActionButtonType = 'primary' | 'action' | 'danger'

interface ActionButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: ActionButtonType
  shadow?: boolean
  hasLabel?: boolean
}

const ActionButton: React.FC<ActionButtonProps> = ({
  type = 'action',
  children,
  className,
  hasLabel,
  ...props
}) => {
  return (
    <button
      type="button"
      className={clsx(
        'flex items-center justify-center rounded-full min-w-[45px] h-[45px]',
        {
          'px-4': hasLabel,
          'bg-skin-primary border border-skin-primary text-skin-a11y hover:bg-black-700':
            type === 'primary',
          'bg-container text-black hover:bg-black hover:text-white':
            type === 'action',
          'bg-white text-black hover:text-red-600 hover:bg-white':
            type === 'danger',
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default ActionButton
