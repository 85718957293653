import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { cleanImageUrl } from '@carrotcart/client-common/lib/helpers'
import PictureAvatar from '@carrotcart/app/components/PictureAvatar'
import VerifiedIcon from '@carrotcart/app/components/svg/VerifiedIcon'

interface UserPictureContainerProps {
  user: any
  nameOverride?: React.ReactNode
  shadow?: boolean
  hideBadge?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  button?: React.ReactNode
}

const UserPictureContainer: React.FC<UserPictureContainerProps> = ({
  user,
  nameOverride,
  children,
  size = 'md',
  shadow,
  hideBadge,
  button,
}) => {
  const dimensions = {
    sm: 30,
    md: 38,
    lg: 50,
    xl: 68,
  }

  return (
    <div className={`flex items-center`}>
      <div className={`w-full flex justify-between items-center mr-2`}>
        <div className="flex items-center">
          <Link href={`/${user?.username}`} target="_blank">
            <div
              className={clsx('rounded-full mr-2 cursor-pointer', {
                shadow,
              })}
            >
              {user?.picture !== null && user?.picture !== undefined ? (
                <PictureAvatar
                  size="large"
                  picture={cleanImageUrl(user?.picture)}
                />
              ) : (
                <div
                  className={`rounded-full`}
                  style={{
                    width: dimensions[size],
                    height: dimensions[size],
                  }}
                />
              )}
            </div>
          </Link>
          <div
            className={`justify-center`}
            style={{
              height: dimensions[size],
            }}
          >
            {nameOverride || (
              <div className={`flex flex-row items-center cursor-pointer`}>
                <p className={`text-base font-semibold leading-[16px]`}>
                  @{user?.username}
                </p>
                {user?.verified && !hideBadge && <VerifiedIcon height={16} />}
              </div>
            )}
            {children}
          </div>
        </div>

        {button}
      </div>
    </div>
  )
}

export default UserPictureContainer
