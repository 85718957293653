import { useRef } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import Image from 'next/legacy/image'
import { EmblaCarouselType } from 'embla-carousel'
import type { GetFeaturedShopsQuery } from '@carrotcart/data/generated'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import ShopFavicon from '@carrotcart/client-common/components/ShopFavicon'
import imageKitLoader from '@carrotcart/client-common/lib/imageKitLoader'
import Carousel from '@carrotcart/app/components/Carousel'

interface FeaturedShopsProps {
  shopPopularity: GetFeaturedShopsQuery['shop_popularity']
}

const FeaturedShops: React.FC<FeaturedShopsProps> = ({ shopPopularity }) => {
  const shops = shopPopularity?.map((sp) => sp.shop) || []
  const carouselApi = useRef<EmblaCarouselType>()

  const getCarouselItems = () => {
    return shops?.map((shop, index) => {
      const shopUrl = `/shop/${shop.display_name?.toLowerCase()}`
      return (
        <Link
          href={shopUrl}
          key={shop.id}
          target="_blank"
          onClick={() =>
            analytics.track(AnalyticsEventName.ClickedDiscoveryItem, {
              shopId: shop?.id,
              source: 'discover',
              cardType: 'trending-shop',
            })
          }
        >
          <div
            className={clsx(
              'relative w-[180px] overflow-hidden rounded-md cursor-pointer mx-1',
              {
                'pl-0': index == 0,
                'pr-0': index == shops.length - 1,
              }
            )}
          >
            <div className="relative h-full w-full">
              <Image
                className="h-full"
                src={shop.cover_image}
                loader={imageKitLoader}
                alt={shop.display_name}
                objectFit="cover"
                layout="responsive"
                width={320}
                height={533}
              />
            </div>
            <div className="absolute bottom-12 flex justify-center h-10 w-full">
              <ShopFavicon
                src={shop.favicon}
                className="w-10 h-10"
                shadow={false}
              />
            </div>
            <div className="bg-gray-100 flex justify-center text-sm py-2 line-clamp-1 text-center">
              {shop.display_name}
            </div>
          </div>
        </Link>
      )
    })
  }

  return (
    <div>
      <div className="text-xl font-bold mb-3">Trending shops</div>
      <Carousel
        apiRef={carouselApi}
        className={clsx('relative overflow-hidden')}
        dots={false}
        navigation={false}
        options={{
          slidesToScroll: 1,
          align: 'start',
          dragFree: true,
          loop: true,
          speed: 6,
        }}
        autoplayOptions={{
          delay: 2200,
          stopOnMouseEnter: true,
          stopOnInteraction: false,
        }}
        items={getCarouselItems()}
      />
    </div>
  )
}

export default FeaturedShops
