import { useRef } from 'react'
import Link from 'next/link'
import {
  GetStealTheirLooksWebQuery,
  useGetStealTheirLooksWebQuery,
} from '@carrotcart/app/data/discover/getStealTheirLooksWeb.generated'
import ProductCardImage from '@carrotcart/app/components/ProductCardImage'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'

interface StealTheirLooksProps {
  stlItems?: GetStealTheirLooksWebQuery['steal_their_looks_random'][0][]
}

const StealTheirLooks: React.FC<StealTheirLooksProps> = ({
  stlItems: initialItems,
}) => {
  const seed = useRef(Math.random().toString()).current
  const { data } = useGetStealTheirLooksWebQuery({
    variables: {
      seed,
    },
    skip: !!initialItems?.length,
  })

  const stlItems = (
    initialItems ||
    data?.steal_their_looks_random ||
    []
  ).filter((stl) => !!stl.preview_collection_items.length)

  return (
    <div className="">
      <div className="font-bold text-xl mb-3">Steal their looks</div>
      <div className="flex gap-2">
        {stlItems.map((collection) => {
          const collectionCoverImage = collection?.cover_image
          const cartItem = collection.preview_collection_items?.[0]?.cart_item
          const shopItem =
            collection.preview_collection_items?.[0]?.cart_item?.item
          const imageSource =
            collectionCoverImage ||
            cartItem?.item_image_override ||
            shopItem?.cached_image
          return (
            <Link
              href={`/${collection.user.username}/${collection.slug}`}
              key={collection.id}
              target="_blank"
              onClick={() =>
                analytics.track(AnalyticsEventName.ClickedDiscoveryItem, {
                  itemId: cartItem?.id,
                  source: 'discover',
                  cardType: 'looks',
                })
              }
            >
              <div className="cursor-pointer aspect-[5/9] relative overflow-hidden rounded-md">
                <ProductCardImage
                  item={shopItem}
                  cartItem={cartItem}
                  itemImageOverride={imageSource}
                  objectFit="cover"
                  width={400}
                  height={720}
                />
                <div className="absolute z-10 pb-2 md:pb-4 px-1 text-white bottom-0 h-10 leading-[1.2] font-semibold text-sm md:text-md text-ellipsis text-center w-full flex justify-center items-end bg-gradient-to-t from-black/50 to-black/0">
                  {collection.user.name}
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default StealTheirLooks
