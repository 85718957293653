import Link from 'next/link'
import {
  DiscoveryFeedFeedItemFragment,
  Feed_Item_Type_Enum,
} from '@carrotcart/data/generated'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import { usePremiumContentContext } from '@carrotcart/app/context/PremiumContentProvider'
import { useSignupWallContext } from '@carrotcart/app/context/SignupWallProvider'
import useGetDiscoverData from '@carrotcart/app/hooks/useGetDiscoverData'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import useSubscribeToCreator from '@carrotcart/app/hooks/useSubscribeToCreator'
import Button from '@carrotcart/client-common/components/Button'
import UserPictureContainer from './DiscoverUserPicture'

interface DiscoverTopProps {
  feedItem: DiscoveryFeedFeedItemFragment
  creator: any
}

const DiscoverTop: React.FC<DiscoverTopProps> = ({ feedItem, creator }) => {
  const { authUser } = useAuthUser()
  const { subscriptionStatus, refetchCreatorSubscriptions } =
    usePremiumContentContext()
  const { subscribeCallback } = useSubscribeToCreator()
  const { openSignupWall } = useSignupWallContext()
  const { collectionUrl } = useGetDiscoverData({ feedItem })

  const handleSubscribeClick = async () => {
    if (authUser) {
      await subscribeCallback(creator.id)
      await refetchCreatorSubscriptions()
    } else {
      openSignupWall({
        type: 'with_custom_callback',
        trackingData: {
          source: 'discover',
          trigger: 'subscribe',
        },
        callback: async () => {
          await subscribeCallback(creator.id)
          await refetchCreatorSubscriptions()
        },
      })
    }

    analytics.track(AnalyticsEventName.ClickedSubscribeCTA, {
      source: 'discover',
      creatorId: creator.id,
      anonymous: !authUser,
    })
  }

  const getSubscribeButton = () => {
    return authUser && subscriptionStatus !== 'none' ? (
      <div className="bg-gray-400 rounded-full px-5 h-[42px] flex items-center text-white">
        Subscribed
      </div>
    ) : (
      <Button onClick={handleSubscribeClick}>Subscribe</Button>
    )
  }

  switch (feedItem.item_type) {
    case Feed_Item_Type_Enum.FeaturedShop:
      return (
        <UserPictureContainer
          user={feedItem.featured_shop?.shop.cart_items[0].cart.user as any}
          shadow
          button={getSubscribeButton()}
        >
          <p
            className="flex-1 text-gray-400"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            Discovered a trendy new shop 🔥
          </p>
        </UserPictureContainer>
      )
    case Feed_Item_Type_Enum.Collection:
      return (
        <UserPictureContainer
          user={feedItem.collection?.user as any}
          shadow
          button={getSubscribeButton()}
        >
          <p
            className="flex-1 text-gray-400 line-clamp-1"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            Added items to{' '}
            <Link href={collectionUrl} className="text-black">
              {feedItem.collection?.name}
            </Link>
          </p>
        </UserPictureContainer>
      )
    case Feed_Item_Type_Enum.CollectionCartItem:
      return (
        <UserPictureContainer
          user={feedItem.collection_cart_item?.collection.user as any}
          shadow
          button={getSubscribeButton()}
        >
          <p className="text-gray-400 text-ellipsis line-clamp-1 pr-2 cursor-pointer">
            Added to{' '}
            <Link href={collectionUrl} className="text-black" target="_blank">
              {feedItem.collection_cart_item?.collection.name}
            </Link>
          </p>
        </UserPictureContainer>
      )
    default:
      return null
  }
}

export default DiscoverTop
