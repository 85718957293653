import React, { Fragment, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Dialog, Transition } from '@headlessui/react'
import { DotsVerticalIcon, XIcon } from '@heroicons/react/solid'
import useWindowSize from '@carrotcart/client-common/hooks/useWindowSize'

export type ModalSize =
  | number
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | 'xxx-large'
  | 'full'

interface ModalDialogProps {
  children({
    isOpen,
    closeModal,
    setModalSize,
  }: {
    isOpen: boolean
    closeModal(): void
    setModalSize: React.Dispatch<React.SetStateAction<ModalSize>>
  }): React.ReactNode
  size?: ModalSize
  shadow?: boolean
  rounded?: boolean
  disabled?: boolean
  showDots?: boolean
  showXClose?: boolean
  xCloseClassaName?: string
  hideOverlay?: boolean
  blurBackground?: boolean
  disableClose?: boolean
  dotColor?: string
  bg?: string
  trigger?: React.ReactNode
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined
  autoOpen?: boolean | undefined
  onClose?: () => void
  onOpen?: () => void
  onTriggerCallback?: () => void
  modalClassName?: string
  wrapperClassName?: string
  className?: string
  isHidden?: boolean
  overflowHidden?: boolean
  overflowInitial?: boolean
  unmount?: boolean
  shouldBlur?: boolean
}

const ModalDialog: React.FC<ModalDialogProps> = ({
  size = 'medium',
  children,
  shadow = true,
  rounded = true,
  disabled = false,
  showDots = false,
  showXClose = false,
  xCloseClassaName,
  hideOverlay = false,
  blurBackground = false,
  disableClose = false,
  dotColor = 'currentColor',
  bg = 'bg-white',
  trigger,
  initialFocus,
  autoOpen,
  onClose,
  onOpen,
  onTriggerCallback,
  modalClassName,
  wrapperClassName,
  className,
  isHidden,
  overflowHidden = true,
  overflowInitial = false,
  unmount = false,
  shouldBlur = false,
}) => {
  const { width, height } = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)
  const [modalSize, setModalSize] = useState(size)

  const sizeIsNumber = typeof modalSize === 'number' || Number(modalSize)

  const closeModal = useCallback(() => {
    setTimeout(() => {
      setIsOpen(false)
      setModalSize(size)
      if (onClose) onClose()
    }, 0)
  }, [onClose, size])

  const openModal = useCallback(() => {
    if (disabled) return
    setIsOpen(true)
    if (onOpen) onOpen()
  }, [disabled, onOpen])

  // ensure modal closed at unmount
  useEffect(() => {
    return () => {
      setIsOpen(false)
    }
  }, [])

  // auto open if `autoOpen` is true
  useEffect(() => {
    if (!width) return
    if (!height) return
    if (isOpen) return

    const timeout = setTimeout(() => {
      if (autoOpen) {
        openModal()
      }
    }, 0)

    return () => clearTimeout(timeout)
  }, [autoOpen, height, isOpen, openModal, width])

  return (
    <>
      {(trigger || showDots) && (
        <div
          className={clsx(
            'inline-flex items-center focus:outline-none',
            className,
            {
              'cursor-default': disabled,
              'cursor-pointer': !disabled,
            }
          )}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            openModal()
            if (onTriggerCallback) onTriggerCallback()
          }}
        >
          {trigger}
          {showDots && !disabled && (
            <div className={clsx('p-2', dotColor)}>
              <DotsVerticalIcon className="w-4 h-4" />
            </div>
          )}
        </div>
      )}

      <Transition show={isOpen && !disabled && !isHidden} as={Fragment}>
        <Dialog
          as="div"
          className={clsx(
            'flex flex-col items-center justify-center fixed inset-0 z-50 overflow-y-auto',
            {
              'blur-md pointer-events-none': shouldBlur,
            }
          )}
          onClose={() => {
            if (disableClose) return
            closeModal()
          }}
          onClick={(e: any) => {
            e.stopPropagation()
          }}
          initialFocus={initialFocus}
          unmount={unmount}
        >
          {!hideOverlay && (
            <Dialog.Overlay
              className={clsx(
                'fixed inset-0 bg-black bg-opacity-50 md:bg-transparent md:bg-gradient-to-t md:from-black',
                {
                  'backdrop-blur': blurBackground,
                }
              )}
            />
          )}

          <div
            className={clsx(
              'min-h-screen px-2 text-center w-full',
              wrapperClassName
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 md:scale-95 translate-y-full md:translate-y-0"
              enterTo="transform opacity-100 md:scale-100 translate-y-0"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 md:scale-100 translate-y-0"
              leaveTo="transform opacity-0 md:scale-95 translate-y-full md:translate-y-0"
            >
              <div
                className={clsx(
                  `fixed bottom-0 inset-x-0 md:relative inline-block w-full text-left align-middle transition-all transform scrollbar-hide ${bg}`,
                  {
                    'max-w-md': modalSize === 'medium',
                    'max-w-lg': modalSize === 'large',
                    'max-w-xl': modalSize === 'x-large',
                    'max-w-3xl': modalSize === 'xx-large',
                    'max-w-[988px]': modalSize === 'xxx-large',
                    'shadow-xl': shadow,
                    'rounded-t-xl md:rounded-xl': rounded,
                    'overflow-hidden': overflowHidden && !overflowInitial,
                    'overflow-auto': !overflowHidden && !overflowInitial,
                    'overflow-initial': overflowInitial,
                  },
                  modalClassName
                )}
                style={
                  sizeIsNumber
                    ? {
                        maxWidth: `${modalSize}px`,
                      }
                    : {}
                }
              >
                {children({ isOpen, closeModal, setModalSize })}
                {showXClose && (
                  <XIcon
                    onClick={closeModal}
                    className={clsx(
                      'w-7 h-7 p-1 absolute top-3 right-3 text-opacity-40 hover:text-opacity-100 transition-colors cursor-pointer',
                      xCloseClassaName
                    )}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export const closeOpenDialog = (): void => {
  try {
    const overlay = document.querySelector(
      '#headlessui-portal-root .fixed .fixed'
    ) as HTMLElement
    overlay && overlay.click()
  } catch (e) {
    //no-op
  }
}

export default React.memo(ModalDialog)
