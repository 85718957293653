import * as React from 'react'
import { SVGProps, memo } from 'react'

const CommentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 1.188C4.753 1.188.501 4.642.501 8.905c0 1.768.739 3.386 1.964 4.683-.553 1.466-1.703 2.705-1.721 2.719a.893.893 0 0 0 .647 1.505c2.282 0 4.048-.956 5.162-1.717 1.039.336 2.234.529 3.447.529 5.247 0 9.467-3.456 9.467-7.719S15.247 1.187 10 1.187Zm.004 13.656a9.628 9.628 0 0 1-2.909-.45l-.844-.264-.724.51a7.94 7.94 0 0 1-2.134 1.076c.274-.45.534-.956.738-1.494l.394-1.039-.765-.811c-.669-.716-1.476-1.9-1.476-3.466 0-3.275 3.46-5.937 7.719-5.937 4.258 0 7.718 2.662 7.718 5.937 0 3.275-3.46 5.938-7.717 5.938Z"
    />
  </svg>
)
const Memo = memo(CommentIcon)
export default Memo
