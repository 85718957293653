import type * as Types from '../generated/types'

import type { FeedItemDataFragment } from './fragments.generated'
import { gql } from '@apollo/client'
import { FeedItemDataFragmentDoc } from './fragments.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type LikeFeedItemActionMutationVariables = Types.Exact<{
  input: Types.FeedItemLikeInput
}>

export type LikeFeedItemActionMutation = {
  __typename?: 'mutation_root'
  likeFeedItem?: Types.Maybe<{
    __typename?: 'FeedItemLikeOutput'
    feed_item_like?: Types.Maybe<{
      __typename?: 'feed_item_like'
      id: any
      feed_item: { __typename?: 'feed_item' } & FeedItemDataFragment
    }>
  }>
}

export const LikeFeedItemActionDocument = gql`
  mutation LikeFeedItemAction($input: FeedItemLikeInput!) {
    likeFeedItem(likeData: $input) {
      feed_item_like {
        id
        feed_item {
          ...feedItemData
        }
      }
    }
  }
  ${FeedItemDataFragmentDoc}
`
export type LikeFeedItemActionMutationFn = Apollo.MutationFunction<
  LikeFeedItemActionMutation,
  LikeFeedItemActionMutationVariables
>

/**
 * __useLikeFeedItemActionMutation__
 *
 * To run a mutation, you first call `useLikeFeedItemActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeFeedItemActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeFeedItemActionMutation, { data, loading, error }] = useLikeFeedItemActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikeFeedItemActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LikeFeedItemActionMutation,
    LikeFeedItemActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LikeFeedItemActionMutation,
    LikeFeedItemActionMutationVariables
  >(LikeFeedItemActionDocument, options)
}
export type LikeFeedItemActionMutationHookResult = ReturnType<
  typeof useLikeFeedItemActionMutation
>
export type LikeFeedItemActionMutationResult =
  Apollo.MutationResult<LikeFeedItemActionMutation>
export type LikeFeedItemActionMutationOptions = Apollo.BaseMutationOptions<
  LikeFeedItemActionMutation,
  LikeFeedItemActionMutationVariables
>
