import type * as Types from '../../../data/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type GetStealTheirLooksWebQueryVariables = Types.Exact<{
  seed?: Types.Maybe<Types.Scalars['seed_float']>
  limit?: Types.Maybe<Types.Scalars['Int']>
}>

export type GetStealTheirLooksWebQuery = {
  __typename?: 'query_root'
  steal_their_looks_random: Array<{
    __typename?: 'collection'
    id: any
    slug: any
    cover_image?: Types.Maybe<string>
    preview_collection_items?: Types.Maybe<
      Array<{
        __typename?: 'collection_cart_item'
        id: any
        cart_item: {
          __typename?: 'cart_item'
          id: any
          item_image_override?: Types.Maybe<string>
          image_metadata?: Types.Maybe<any>
          cart: {
            __typename?: 'cart'
            id: any
            user: { __typename?: 'user'; id: string }
          }
          item: {
            __typename?: 'shop_item'
            id: any
            cached_image?: Types.Maybe<string>
            image?: Types.Maybe<string>
            image_highres?: Types.Maybe<string>
            image_metadata?: Types.Maybe<any>
            metadata?: Types.Maybe<any>
            is_inappropriate: boolean
          }
        }
      }>
    >
    user?: Types.Maybe<{
      __typename?: 'user'
      id: string
      username?: Types.Maybe<string>
      name?: Types.Maybe<string>
    }>
  }>
}

export const GetStealTheirLooksWebDocument = gql`
  query GetStealTheirLooksWeb($seed: seed_float = "0.0", $limit: Int = 3) {
    steal_their_looks_random(args: { seed: $seed }, limit: $limit) {
      id
      slug
      cover_image
      preview_collection_items(limit: 1) {
        id
        cart_item {
          id
          item_image_override
          image_metadata
          cart {
            id
            user {
              id
            }
          }
          item {
            id
            cached_image
            image
            image_highres
            cached_image
            image_metadata
            metadata
            is_inappropriate
          }
        }
      }
      user {
        id
        username
        name
      }
    }
  }
`

/**
 * __useGetStealTheirLooksWebQuery__
 *
 * To run a query within a React component, call `useGetStealTheirLooksWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStealTheirLooksWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStealTheirLooksWebQuery({
 *   variables: {
 *      seed: // value for 'seed'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetStealTheirLooksWebQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >(GetStealTheirLooksWebDocument, options)
}
export function useGetStealTheirLooksWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >(GetStealTheirLooksWebDocument, options)
}
export type GetStealTheirLooksWebQueryHookResult = ReturnType<
  typeof useGetStealTheirLooksWebQuery
>
export type GetStealTheirLooksWebLazyQueryHookResult = ReturnType<
  typeof useGetStealTheirLooksWebLazyQuery
>
export type GetStealTheirLooksWebQueryResult = Apollo.QueryResult<
  GetStealTheirLooksWebQuery,
  GetStealTheirLooksWebQueryVariables
>
