import { useMemo, useState, useEffect } from 'react'
import clsx from 'clsx'
import { GetStaticProps, NextPage } from 'next'
import { useForm } from 'react-hook-form'
import { ArrowUpIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { Virtuoso } from 'react-virtuoso'
import clientServer from '@carrotcart/app/lib/apolloClientServer'
import {
  GetDiscoveryFeedWebDocument,
  GetDiscoveryFeedWebQuery,
  GetDiscoveryFeedWebQueryVariables,
  GetFeaturedShopsDocument,
  GetFeaturedShopsQuery,
  GetFeaturedShopsQueryVariables,
  GetStealTheirLooksWebDocument,
  GetStealTheirLooksWebQuery,
  GetStealTheirLooksWebQueryVariables,
  useGetDiscoveryFeedWebQuery,
} from '@carrotcart/data/generated'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import { createPublicUrl } from '@carrotcart/common/lib/helpers'
import { useScrollPosition } from '@carrotcart/client-common/hooks/useScrollPosition'
import Layout from '@carrotcart/app/components/Layout'
import ApolloWebProvider from '@carrotcart/app/context/ApolloWebProvider'
import WebAppProvider, {
  useWebAppContext,
} from '@carrotcart/app/context/WebAppProvider'
import SignupWallProvider, {
  useSignupWallContext,
} from '@carrotcart/app/context/SignupWallProvider'
import DiscoverItem from '@carrotcart/app/components/discover/DiscoverItem'
import DiscoverTop from '@carrotcart/app/components/discover/DiscoverTop'
import PremiumContentProvider from '@carrotcart/app/context/PremiumContentProvider'
import SignUpWallWithCallback from '@carrotcart/app/components/SignUpWallWithCallback'
import FeaturedShops from '@carrotcart/app/components/discover/FeaturedShops'
import StealTheirLooks from '@carrotcart/app/components/discover/StealTheirLooks'
import AddCartItemToCollection from '@carrotcart/app/components/atc-button/AddCartItemToCollection'
import Button from '@carrotcart/client-common/components/Button'
import useMediaQuery from '@carrotcart/app/hooks/useMediaQuery'
import tailwindConfig from '@carrotcart/app/tailwind.config'
import SuggestedTastemakers from '@carrotcart/app/components/discover/SuggestedTastemakers'
import GetCarrotAd from '@carrotcart/app/components/discover/GetCarrotAd'

interface PageProps {
  feed: GetDiscoveryFeedWebQuery['discovery_feed']
  shopPopularity: GetFeaturedShopsQuery['shop_popularity']
  stealTheirLooks: GetStealTheirLooksWebQuery['steal_their_looks_random']
}

type InputData = {
  q: string
}

const NUM_PAGES_TO_LOAD = 3

const getCreator = (
  feedItem: GetDiscoveryFeedWebQuery['discovery_feed'][number]['feed_item']
) => {
  return (
    feedItem?.collection_cart_item?.collection?.user ||
    feedItem?.collection?.user ||
    feedItem?.featured_shop?.shop?.cart_items[0]?.cart?.user
  )
}

const IndexPageComponent: NextPage<PageProps> = ({
  feed: initialFeed,
  shopPopularity,
  stealTheirLooks,
}) => {
  const router = useRouter()
  const query = router.query
  const q = query.q as string
  const matchesScreenMdUp = useMediaQuery(
    `(min-width: ${tailwindConfig.theme.extend.screens.sm})`
  )

  const { currentUser } = useWebAppContext()
  const [pagesLoaded, setPagesLoaded] = useState(1)
  const [showJumpTop, setShowJumpTop] = useState(false)
  const [searchPlaceholder] = useState('What are you shopping for?')
  const { data, fetchMore, loading } = useGetDiscoveryFeedWebQuery()
  const { openSignupWall } = useSignupWallContext()

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -window.innerHeight * 2) {
      setShowJumpTop(true)
    } else {
      setShowJumpTop(false)
    }
  })

  const { register, handleSubmit, setFocus } = useForm<InputData>({
    defaultValues: {
      q,
    },
  })

  const shouldAutoLoadMore = !!(pagesLoaded % NUM_PAGES_TO_LOAD)
  const feed = useMemo(
    () => data?.discovery_feed || initialFeed || [],
    [data?.discovery_feed, initialFeed]
  )
  const fullFeed = useMemo(() => {
    const items = []
    for (const item of feed) {
      const creator = getCreator(item.feed_item)
      items.push(
        <PremiumContentProvider creator={creator}>
          <div key={item.id} className="my-12">
            <div className="mb-3">
              <DiscoverTop feedItem={item.feed_item} creator={creator} />
            </div>
            <DiscoverItem feedItem={item.feed_item} />
          </div>
        </PremiumContentProvider>
      )
    }

    items.splice(3, 0, <FeaturedShops shopPopularity={shopPopularity} />)

    for (let i = 0; i < items.length; i++) {
      if (i % 7 === 0) {
        items.splice(
          i,
          0,
          <StealTheirLooks stlItems={i === 0 ? stealTheirLooks : undefined} />
        )
      }
    }

    return items
  }, [feed, shopPopularity, stealTheirLooks])

  const onSubmit = async (data: InputData) => {
    const { q } = data

    analytics.track(AnalyticsEventName.Search, {
      query: q,
      user_anonymous: !currentUser?.id,
    })

    router.push(`/search?q=${q}`)
  }

  // auto-focus search input on desktop
  useEffect(() => {
    if (!matchesScreenMdUp) return
    setFocus('q')
  }, [matchesScreenMdUp, setFocus])

  return (
    <>
      <Layout
        full
        showNavLinks
        hideProductSearch
        showProductSearchOnScroll
        hideStickySignupBanner
        nextSeo={{
          canonical: createPublicUrl('/'),
          openGraph: {
            url: createPublicUrl('/'),
          },
        }}
      >
        <div className="py-4 mx-auto px-4 md:px-10">
          <div className="text-center md:max-w-3xl xl:max-w-4xl mx-auto md:mt-10">
            <div className="font-display font-black text-4xl sm:text-6xl md:text-7xl mb-8 md:mb-12 px-1 mx-auto">
              <div className="mx-auto max-w-[9em]">
                Hop in, we’re going shopping
              </div>
            </div>
            <div className="relative mx-auto max-w-2xl mb-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="group z-1 flex relative">
                  <input
                    className="peer w-full h-[60px] md:h-[70px] border border-gray border-opacity-50 outline-none rounded-full pl-16 md:pl-20 text-xl md:text-2xl  hover:drop-shadow-[0_25px_40px_rgba(0,0,0,0.4)] focus:drop-shadow-[0_25px_40px_rgba(0,0,0,0.4)] transition-all duration-300"
                    placeholder={searchPlaceholder}
                    name="q"
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="off"
                    onClick={() => {
                      analytics.track('Clicked search input', {
                        expanded: false,
                      })
                    }}
                    {...register('q')}
                  />
                  <img
                    src="/images/home/ic-search-purple.svg"
                    className="pointer-events-none absolute top-1/2 left-6 md:left-8 -translate-y-1/2 saturate-0 peer-focus:saturate-100 group-hover:saturate-100"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            'display grid grid-cols-[0px_1fr_0px] lg:grid-cols-[1fr_600px_1fr] grid-rows-1 w-full gap-3 lg:gap-4'
          )}
        >
          <div className="" />
          <div className="justify-self-center mx-auto max-w-[600px] w-full">
            <div className="pb-[100px]">
              {feed && feed.length > 0 && (
                <Virtuoso
                  useWindowScroll
                  endReached={async () => {
                    if (!loading && !currentUser) {
                      openSignupWall({
                        type: 'with_custom_callback',
                        disableClose: true,
                        disableBgClick: true,
                        trackingData: {
                          source: 'discover',
                          trigger: 'scroll',
                        },
                      })
                    }

                    if (shouldAutoLoadMore) {
                      await fetchMore({ variables: { offset: feed.length } })
                      setPagesLoaded(pagesLoaded + 1)
                    }
                  }}
                  initialItemCount={initialFeed.length}
                  defaultItemHeight={470}
                  totalCount={fullFeed.length}
                  increaseViewportBy={{
                    top: 0,
                    bottom: 1000,
                  }}
                  itemContent={(index) => fullFeed[index]}
                />
              )}
            </div>

            {!shouldAutoLoadMore && !loading && (
              <div className="flex items-center justify-center">
                <Button
                  onClick={async () => {
                    await fetchMore({ variables: { offset: feed.length } })
                    setPagesLoaded(pagesLoaded + 1)
                  }}
                >
                  Load more
                </Button>
              </div>
            )}
          </div>
          <div className="w-[360px] flex-col justify-start mt-[40px] mr-4 hidden lg:block">
            <div className="min-h-[430px]">
              <SuggestedTastemakers />
            </div>
            <div className="mt-4">
              <GetCarrotAd />
            </div>
          </div>
        </div>
        <div
          className={clsx(
            'hidden md:block fixed z-10 bottom-[22px] right-[75px] transition-all',
            {
              'translate-y-[150px]': !showJumpTop,
              'translate-y-0': showJumpTop,
            }
          )}
        >
          <Button
            className="cursor-pointer active:scale-95 transition-all"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0 })
              setFocus('q')
            }}
          >
            <ArrowUpIcon className="w-5 h-5 mr-2" />
            Top
          </Button>
        </div>
        <SignUpWallWithCallback />

        {/* On Page - Add To Collection */}
        <AddCartItemToCollection />
      </Layout>
    </>
  )
}

export default (function IndexPage(props) {
  return (
    <ApolloWebProvider>
      <WebAppProvider>
        <SignupWallProvider>
          <IndexPageComponent {...props} />
        </SignupWallProvider>
      </WebAppProvider>
    </ApolloWebProvider>
  )
} as NextPage<PageProps>)

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const dataResp = await clientServer().query<
    GetDiscoveryFeedWebQuery,
    GetDiscoveryFeedWebQueryVariables
  >({
    query: GetDiscoveryFeedWebDocument,
    variables: {
      limit: 1,
    },
  })

  const featuredShopData = await clientServer().query<
    GetFeaturedShopsQuery,
    GetFeaturedShopsQueryVariables
  >({
    query: GetFeaturedShopsDocument,
  })

  const stealTheirLooksData = await clientServer().query<
    GetStealTheirLooksWebQuery,
    GetStealTheirLooksWebQueryVariables
  >({
    query: GetStealTheirLooksWebDocument,
    variables: {
      seed: Math.random().toString(),
    },
  })

  return {
    props: {
      feed: dataResp?.data?.discovery_feed || [],
      shopPopularity: featuredShopData?.data?.shop_popularity || [],
      stealTheirLooks:
        stealTheirLooksData?.data?.steal_their_looks_random || [],
    },
  }
}
