import {
  BasicUserDataFragment,
  DiscoveryCollectionCartItemFragment,
  DiscoveryCollectionFragment,
  GetDiscoveryFeedWebQuery,
  ItemDataFragment,
} from '@carrotcart/data/generated'

interface UseGetDiscoverDataParams {
  feedItem: GetDiscoveryFeedWebQuery['discovery_feed'][number]['feed_item']
}

const useGetDiscoverData = ({
  feedItem,
}: UseGetDiscoverDataParams): {
  collection: DiscoveryCollectionFragment
  collectionItems: DiscoveryCollectionCartItemFragment[]
  creator: BasicUserDataFragment
  cartItem: DiscoveryCollectionCartItemFragment['cart_item']
  featuredItem: ItemDataFragment
  collectionUrl: string
  collectionWithModalUrl: string
} => {
  const collection =
    feedItem?.collection_cart_item?.collection || feedItem?.collection
  const collectionItems = collection?.collection_items || []
  const creator =
    feedItem?.collection_cart_item?.cart_item?.cart?.user ||
    feedItem?.collection?.user
  const cartItem =
    feedItem?.collection_cart_item?.cart_item || collectionItems[0]?.cart_item
  const featuredItem =
    feedItem?.collection_cart_item?.cart_item?.item ||
    collectionItems[0]?.cart_item.item
  const collectionOwner = collection?.user?.username

  const collectionUrl = `/${collectionOwner}/${collection?.slug}`
  const collectionWithModalUrl = `${collectionUrl}/${cartItem?.id}`

  return {
    collection,
    collectionItems,
    creator,
    cartItem,
    featuredItem,
    collectionUrl,
    collectionWithModalUrl,
  }
}

export default useGetDiscoverData
