import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  ReactEventHandler,
} from 'react'
import clsx from 'clsx'
import type { ImageProps } from 'next/legacy/image'
import type { UploadResponse } from 'imagekit/dist/libs/interfaces'
import {
  CartItemDataFragment,
  ItemDataFragment,
  ItemDataFragmentDoc,
  useUpdateShopItemMetadataMutation,
} from '@carrotcart/data/generated'
import connectToContext, {
  SelectorFn,
} from '@carrotcart/client-common/hoc/connectToContext'
import logger from '@carrotcart/client-common/lib/logger/web'
import imageKitLoader from '@carrotcart/client-common/lib/imageKitLoader'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { PLACEHOLDER_IMAGE_SRC } from '@carrotcart/app/lib/constants'
import DynamicImage from './DynamicImage'

interface ContextSelectorProps {
  currentUserId: string | null
  isAdminUser: boolean
}

const useSelector: SelectorFn<ContextSelectorProps> = () => {
  const { isAdminUser, currentUser } = useWebAppContext()

  return {
    currentUserId: currentUser?.id,
    isAdminUser,
  }
}

interface ProductCardImageProps extends ContextSelectorProps {
  className?: string
  item: Pick<
    ItemDataFragment,
    | 'id'
    | 'image'
    | 'image_highres'
    | 'cached_image'
    | 'image_metadata'
    | 'metadata'
    | 'is_inappropriate'
  >
  cartItem?: Pick<
    CartItemDataFragment,
    'id' | 'item_image_override' | 'image_metadata' | 'cart'
  >
  itemImageOverride?: string
  alt?:
    | ItemDataFragment['display_title']
    | CartItemDataFragment['display_title']
  width?: ImageProps['width']
  height?: ImageProps['height']
  priority?: ImageProps['priority']
  sizes?: ImageProps['sizes']
  objectFit?: ImageProps['objectFit']
  objectPosition?: ImageProps['objectPosition']
  layout?: ImageProps['layout']
  dontUpdateMetadata?: boolean
  disableScaling?: boolean
  disableOverlay?: boolean
  processing?: boolean
}

const ProductCardImage: React.FC<ProductCardImageProps> = ({
  className,
  item,
  cartItem,
  itemImageOverride,
  alt = '',
  width,
  height,
  priority,
  sizes,
  objectFit,
  objectPosition,
  layout,
  dontUpdateMetadata,
  disableScaling,
  disableOverlay,
  currentUserId,
  isAdminUser,
  processing,
}) => {
  const originalImage = item.image
  const cachedImage = item.cached_image
  const defaultImage =
    itemImageOverride ||
    cartItem?.item_image_override ||
    cachedImage ||
    item.image_highres ||
    originalImage

  const metadata = useMemo(() => item?.metadata || {}, [item?.metadata])
  const imageMetadata: UploadResponse = useMemo(
    () => cartItem?.image_metadata || item?.image_metadata || {},
    [cartItem?.image_metadata, item?.image_metadata]
  )
  const loadingImageRef = useRef<{
    failedToLoadCachedImage?: boolean
    failedToLoadImageProxiedViaImageKit?: boolean
    failedToLoadOriginalImage?: boolean
  }>({})
  const logCtxRef = useRef<Record<string, any>>({})
  logCtxRef.current = {
    'context.item.id': item.id,
    'context.item.display_title': alt,
    'context.item.image': originalImage,
    'context.item.cached_image': item.cached_image,
    'context.item.image_highres': item.image_highres,
  }

  const [imageSrc, setImageSrc] = useState(
    defaultImage || PLACEHOLDER_IMAGE_SRC
  )
  const [shouldProxy, setShouldProxy] = useState(true)

  const imageSrcIsPlaceholder = imageSrc === PLACEHOLDER_IMAGE_SRC

  const isOwnItem = currentUserId && cartItem?.cart?.user?.id === currentUserId
  const shouldBlur = (!isOwnItem || isAdminUser) && !!item.is_inappropriate

  const storedWidth = imageMetadata?.width
  const storedHeight = imageMetadata?.height
  const calculatedPaddingTop = useMemo(() => {
    if (!width && storedWidth && storedHeight) {
      const ratio = storedWidth / storedHeight
      const maxRatio = Math.max(3 / 4, ratio)

      return `calc(100% / (${maxRatio})`
    }

    return '1'
  }, [width, storedWidth, storedHeight])

  const [paddingTop, setPaddingTop] = useState(calculatedPaddingTop)

  const [updateShopItemMetadataMutation] = useUpdateShopItemMetadataMutation({
    update: (cache, result) => {
      const item = result.data?.update_shop_item_by_pk
      if (item?.id) {
        cache.writeFragment({
          id: cache.identify(item),
          fragment: ItemDataFragmentDoc,
          fragmentName: 'itemData',
          data: item,
        })
      }
    },
  })

  useEffect(() => {
    if (metadata?.cached_image_failed) {
      setImageSrc(itemImageOverride || originalImage || defaultImage)
    }
  }, [
    metadata?.cached_image_failed,
    originalImage,
    defaultImage,
    itemImageOverride,
  ])

  const onErrorCallback: ReactEventHandler<HTMLImageElement> =
    useCallback(async () => {
      if (imageSrc === PLACEHOLDER_IMAGE_SRC) {
        logger.warn('[PROXIED] Failed to load the placeholder image')
        return
      }

      if (
        typeof loadingImageRef.current.failedToLoadCachedImage ===
          'undefined' &&
        imageSrc === cachedImage
      ) {
        loadingImageRef.current.failedToLoadCachedImage = true
        setImageSrc(defaultImage)
        logger.warn({
          ...logCtxRef.current,
          message: '[CACHED IMAGE] Failed to load cached product image',
        })

        if (!metadata.cached_image_failed && !dontUpdateMetadata) {
          await updateShopItemMetadataMutation({
            variables: {
              id: item.id,
              metadata: {
                cached_image_failed: true,
              },
            },
          })
        }
      } else if (
        loadingImageRef.current.failedToLoadCachedImage &&
        typeof loadingImageRef.current.failedToLoadImageProxiedViaImageKit ===
          'undefined'
      ) {
        loadingImageRef.current.failedToLoadImageProxiedViaImageKit = true
        setShouldProxy(false)
        logger.warn({
          ...logCtxRef.current,
          message:
            '[PROXIED IMAGE] Failed to load the product image proxying it through imagekit',
        })

        if (!metadata.image_kit_proxy_failed && !dontUpdateMetadata) {
          await updateShopItemMetadataMutation({
            variables: {
              id: item.id,
              metadata: {
                image_kit_proxy_failed: true,
              },
            },
          })
        }
      } else if (
        loadingImageRef.current.failedToLoadCachedImage &&
        loadingImageRef.current.failedToLoadImageProxiedViaImageKit &&
        typeof loadingImageRef.current.failedToLoadOriginalImage === 'undefined'
      ) {
        loadingImageRef.current.failedToLoadOriginalImage = true
        setImageSrc(PLACEHOLDER_IMAGE_SRC)

        logger.warn({
          ...logCtxRef.current,
          message: '[ORIGINAL] Failed to load product image',
        })

        if (!metadata.original_image_failed && !dontUpdateMetadata) {
          await updateShopItemMetadataMutation({
            variables: {
              id: item.id,
              metadata: {
                original_image_failed: true,
              },
            },
          })
        }
      }
    }, [
      cachedImage,
      defaultImage,
      dontUpdateMetadata,
      imageSrc,
      item.id,
      metadata.cached_image_failed,
      metadata.image_kit_proxy_failed,
      metadata.original_image_failed,
      updateShopItemMetadataMutation,
    ])

  if (!imageSrc) return null

  return (
    <div
      data-id={item.id}
      data-image={JSON.stringify(originalImage)}
      data-image_highres={JSON.stringify(item.image_highres)}
      data-cached_image={JSON.stringify(item.cached_image)}
      data-src={JSON.stringify(imageSrc)}
      data-metadata={JSON.stringify(metadata)}
      className={clsx(
        'flex h-full group relative items-center justify-center bg-[#F6F6F6]',
        className,
        {
          'blur-2xl': shouldBlur,
        }
      )}
      data-calculated-paddingtop={calculatedPaddingTop}
      data-paddingtop={paddingTop}
      style={{
        paddingTop,
      }}
    >
      {processing ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex flex-col items-center space-y-2">
            <div className="text-black/50">Processing</div>
            <img src={'/images/three-dots.svg'} width={28} />
          </div>
        </div>
      ) : (
        <>
          <DynamicImage
            src={imageSrc}
            alt={alt}
            layout={layout}
            sizes={sizes}
            width={width}
            height={height}
            objectFit={objectFit}
            objectPosition={objectPosition}
            priority={priority}
            imageSrcIsPlaceholder={imageSrcIsPlaceholder}
            shouldProxy={shouldProxy}
            disableScaling={disableScaling}
            setPaddingTop={setPaddingTop}
            onErrorCallback={onErrorCallback}
            loaderCallback={({ src, width, quality }) => {
              if (
                metadata?.cached_image_failed ||
                imageMetadata?.fileType === 'non-image'
              ) {
                return src
              } else {
                return imageKitLoader({ src, width, quality })
              }
            }}
          />

          {!disableOverlay && (
            <div className="bg-[rgba(0,0,0,0.15)] absolute bottom-0 left-0 right-0 top-0 z-1 bg-blend-overlay opacity-30 group-hover:opacity-80 transition-all" />
          )}
          {isAdminUser &&
            (metadata?.cached_image_failed ||
              metadata?.original_image_failed ||
              !originalImage) && (
              <div className="absolute right-1 top-1 flex space-x-1">
                {metadata?.cached_image_failed && (
                  <div
                    className="bg-orange w-3 h-3 rounded-full"
                    title="Failed to load cached image"
                  />
                )}
                {metadata?.original_image_failed && (
                  <div
                    className="bg-red w-3 h-3 rounded-full"
                    title="Failed to load original image"
                  />
                )}
                {!originalImage && (
                  <div
                    className="bg-gray w-3 h-3 rounded-full"
                    title="Null or empty image url"
                  />
                )}
              </div>
            )}
        </>
      )}
    </div>
  )
}

export default connectToContext(ProductCardImage, useSelector)
