import clsx from 'clsx'
import Link from 'next/link'
import type { DiscoveryFeedFeedItemFragment } from '@carrotcart/data/generated'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import useGetDiscoverData from '@carrotcart/app/hooks/useGetDiscoverData'
import ProductCardImage from '@carrotcart/app/components/ProductCardImage'
import DiscoverBottom from './DiscoverBottom'

interface DiscoverCollectionGalleryProps {
  feedItem?: DiscoveryFeedFeedItemFragment
}

const DiscoverCollectionGallery: React.FC<DiscoverCollectionGalleryProps> = ({
  feedItem,
}) => {
  const {
    collection,
    collectionItems,
    cartItem,
    featuredItem,
    collectionUrl,
    collectionWithModalUrl,
  } = useGetDiscoverData({ feedItem })

  return (
    <>
      <div
        onClick={() =>
          analytics.track(AnalyticsEventName.ClickedDiscoveryItem, {
            itemId: cartItem?.id,
            source: 'discover',
            cardType: 'collectionGallery',
          })
        }
      >
        <div className="flex flex-row w-full">
          {collectionItems.slice(1, 5).map((ci, index) => (
            <Link href={collectionUrl} key={ci.id} target="_blank">
              <div
                className={clsx(
                  'relative overflow-hidden rounded-lg basis-1/4 aspect-1 mx-1 cursor-pointer',
                  index === 0 && 'ml-0',
                  index === 3 && 'mr-0'
                )}
              >
                <ProductCardImage
                  item={ci.cart_item.item}
                  itemImageOverride={ci.cart_item.item_image_override}
                  objectFit="cover"
                  width={200}
                  height={200}
                />
                {index === 3 && collectionItems && collectionItems.length > 4 && (
                  <div className="absolute inset-0 flex items-center justify-center rounded-lg bg-black bg-opacity-40">
                    <span className="text-white font-bold text-2xl">
                      +{collection.cart_items_count - 4}
                    </span>
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>

        <Link
          href={collectionWithModalUrl}
          target="_blank"
          className="block my-2"
        >
          <ProductCardImage
            item={featuredItem}
            itemImageOverride={cartItem?.item_image_override}
            className="aspect-1 rounded-lg overflow-hidden cursor-pointer"
            objectFit="cover"
            width={600}
            height={600}
          />
        </Link>
      </div>
      <div className="mt-3">
        <DiscoverBottom feedItem={feedItem} />
      </div>
    </>
  )
}

export default DiscoverCollectionGallery
