import { useRef, useState } from 'react'
import Link from 'next/link'
import { EXCLUDE_USERNAMES } from '@carrotcart/common/lib/constants'
import PremiumContentProvider, {
  usePremiumContentContext,
} from '@carrotcart/app/context/PremiumContentProvider'
import { useSignupWallContext } from '@carrotcart/app/context/SignupWallProvider'
import { useGetCreatorsToSubscribeRandomQuery } from '@carrotcart/app/data/getCreatorsToSubscribeRandom.generated'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import useSubscribeToCreator from '@carrotcart/app/hooks/useSubscribeToCreator'
import PictureAvatar from '@carrotcart/app/components/PictureAvatar'
import Button from '@carrotcart/client-common/components/Button'
import VerifiedIcon from '@carrotcart/app/components/svg/VerifiedIcon'

const TastemakerRow = ({ tastemaker }) => {
  const { authUser } = useAuthUser()
  const { subscribeCallback } = useSubscribeToCreator()
  const { subscriptionStatus, refetchCreatorSubscriptions } =
    usePremiumContentContext()
  const { openSignupWall } = useSignupWallContext()

  const handleSubscribeClick = async (creator) => {
    if (authUser) {
      await subscribeCallback(creator.id)
      await refetchCreatorSubscriptions()
    } else {
      openSignupWall({
        type: 'with_custom_callback',
        trackingData: {
          source: 'discover',
          trigger: 'tastemakers',
        },
        callback: async () => {
          await subscribeCallback(creator.id)
          await refetchCreatorSubscriptions()
        },
      })
    }
  }

  return (
    <div key={tastemaker.id}>
      <div className="my-6 flex items-center justify-between w-full">
        <Link
          href={`/${tastemaker.username}`}
          target="_blank"
          className="flex-1 flex items-center space-x-2"
        >
          <PictureAvatar picture={tastemaker.picture} size="large" />
          <div className="flex-1 ">
            <div className="flex flex-nowrap items-center text-sm font-bold leading-4">
              <div className="whitespace-nowrap truncate max-w-[135px]">
                {tastemaker.name}
              </div>
              {tastemaker.verified && (
                <VerifiedIcon width={14} height={14} className="ml-1" />
              )}
            </div>
            <div className="text-sm text-gray-500 whitespace-nowrap truncate max-w-[150px]">
              @{tastemaker.username}
            </div>
          </div>
        </Link>
        <div className="justify-self-end">
          {authUser && subscriptionStatus !== 'none' ? (
            <div className="bg-gray-400 rounded-full text-sm px-4 h-[38px] flex items-center text-white">
              Subscribed
            </div>
          ) : (
            <Button
              size="small"
              onClick={() => handleSubscribeClick(tastemaker)}
            >
              Subscribe
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

const FIRST_COUNT = 5
const TOTAL_COUNT = 10
const SkeletonTastemakerRow = () => {
  return (
    <>
      <div className="flex w-full mb-4">
        <div className="flex items-center w-full">
          <div className="w-12 h-12 rounded-full skeleton-box-alt mr-4" />
          <div className="w-[180px]">
            <div className="w-full h-5 rounded-full skeleton-box-alt mb-2" />
            <div className="w-[60%] h-2 rounded-full skeleton-box-alt" />
          </div>
        </div>
      </div>
    </>
  )
}

const SuggestedTastemakers: React.FC = () => {
  const [tastemakerShowMore, setTastemakerShowMore] = useState(false)

  const seed = useRef(Math.random().toString()).current

  const { data } = useGetCreatorsToSubscribeRandomQuery({
    variables: {
      exclude_usernames: EXCLUDE_USERNAMES,
      seed,
      include_other_creators: false,
    },
    fetchPolicy: 'cache-and-network',
  })

  const tastemakers = data?.vip_creators || []

  return (
    <div className="bg-alt w-full p-6 rounded-lg mb-2 min-h-[430px]">
      <div className="font-bold mb-4">Suggested tastemakers</div>
      <div className="mb-3">
        {!tastemakers.length && (
          <>
            <SkeletonTastemakerRow />
            <SkeletonTastemakerRow />
            <SkeletonTastemakerRow />
            <SkeletonTastemakerRow />
            <SkeletonTastemakerRow />
          </>
        )}

        {tastemakers
          .slice(0, tastemakerShowMore ? TOTAL_COUNT : FIRST_COUNT)
          .map((tastemaker) => (
            <PremiumContentProvider key={tastemaker.id} creator={tastemaker}>
              <TastemakerRow tastemaker={tastemaker} />
            </PremiumContentProvider>
          ))}
      </div>

      {!tastemakerShowMore && (
        <div
          onClick={() => setTastemakerShowMore(true)}
          className="text-purple cursor-pointer"
        >
          Show more
        </div>
      )}
    </div>
  )
}

export default SuggestedTastemakers
